import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    InputAdornment,
    ThemeProvider,
    Autocomplete
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import {
    Business,
    Description,
    Phone,
    Email,
    Person,
    AccessTime,
    SaveAlt,
    Save,
    ArrowBack,
    Cancel,
} from "@mui/icons-material";
import theme from "../theme/Theme";

const AddEditDepartments = () => {
    const id = localStorage.getItem("editId");
    const navigate = useNavigate();
    const [department, setDepartment] = useState({
        departmentName: "",
        departmentDescription: "",
        phoneNumber: "",
        emailAddress: "",
        departmentHeadID: "",
        operatingHours: "",
    });
    const [isEdit, setIsEdit] = useState(false);
    const [staffMembers, setStaffMembers] = useState([]);
    const [previousHeadID, setPreviousHeadID] = useState(null);
    const [errors, setErrors] = useState({
        departmentName: "",
        departmentDescription: "",
        phoneNumber: "",
        emailAddress: "",
        operatingHours: "",
    });

    useEffect(() => {
        if (id) {
            fetchDepartmentDetails();
        }
        fetchStaffMembers();
    }, [id]);

    const validateInputs = () => {
        let tempErrors = {};

        // Department Name: Only allows letters, numbers, and spaces
        if (!/^[a-zA-Z0-9 ]{2,50}$/.test(department.departmentName)) {
            tempErrors.departmentName = "Department Name should be 2-50 characters long and contain only letters, numbers, and spaces.";
        }

        // Department Description: Just check if it's not empty
        if (!department.departmentDescription.trim()) {
            tempErrors.departmentDescription = "Department Description is required.";
        }

        // Phone Number: Allows only numbers and has a length between 7-15
        if (!/^\d{7,15}$/.test(department.phoneNumber)) {
            tempErrors.phoneNumber = "Phone Number should be 7-15 digits long.";
        }

        // Email Address: Validate email format
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(department.emailAddress)) {
            tempErrors.emailAddress = "Enter a valid email address.";
        }

        // Operating Hours: Should be a valid time format
        if (!department.operatingHours) {
            tempErrors.operatingHours = "Enter a valid time in HH:MM AM/PM format.";
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const fetchDepartmentDetails = async () => {
        try {
            const response = await axios.get(`https://api-govhub.tharuksha.com/api/departments/${id}`);
            setDepartment(response.data);
            setIsEdit(true);
            setPreviousHeadID(response.data.departmentHeadID);
        } catch (error) {
            console.error("Error fetching department details:", error);
            toast.error("Error fetching department details");
        }
    };

    const fetchStaffMembers = async () => {
        try {
            const response = await axios.get("https://api-govhub.tharuksha.com/api/staff");
            setStaffMembers(response.data);
        } catch (error) {
            console.error("Error fetching staff members:", error);
            toast.error("Error fetching staff members");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDepartment({ ...department, [name]: value });

        let tempErrors = { ...errors };

        switch (name) {
            case "departmentName":
                if (!/^[a-zA-Z0-9 ]{2,50}$/.test(value)) {
                    tempErrors.departmentName = "Department Name should be 2-50 characters long and contain only letters, numbers, and spaces.";
                } else {
                    tempErrors.departmentName = "";
                }
                break;
            case "departmentDescription":
                if (!value.trim()) {
                    tempErrors.departmentDescription = "Department Description is required.";
                } else {
                    tempErrors.departmentDescription = "";
                }
                break;
            case "phoneNumber":
                if (!/^\d{7,15}$/.test(value)) {
                    tempErrors.phoneNumber = "Phone Number should be 7-15 digits long.";
                } else {
                    tempErrors.phoneNumber = "";
                }
                break;
            case "emailAddress":
                if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                    tempErrors.emailAddress = "Enter a valid email address.";
                } else {
                    tempErrors.emailAddress = "";
                }
                break;
            case "operatingHours":
                if (!value) {
                    tempErrors.operatingHours = "Enter a valid time in HH:MM AM/PM format.";
                } else {
                    tempErrors.operatingHours = "";
                }
                break;
            default:
                break;
        }

        setErrors(tempErrors);
    };


    const handleDepartmentHeadChange = (event, newValue) => {
        setDepartment({ ...department, departmentHeadID: newValue?._id || "" });
    };

    const goBack = () => {
        localStorage.removeItem('editId');
        navigate(-1);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Final validation check before submission
        if (!validateInputs()) {
            toast.error("Please fix the validation errors before submitting.");
            return;
        }

        try {
            if (isEdit) {
                // Update the department
                await axios.put(`https://api-govhub.tharuksha.com/api/departments/${id}`, department);

                if (department.departmentHeadID) {
                    // If department head is changed, update roles
                    if (department.departmentHeadID !== previousHeadID) {
                        if (previousHeadID) {
                            const departmentsResponse = await axios.get("https://api-govhub.tharuksha.com/api/departments");
                            const departments = departmentsResponse.data;

                            // Check if the previous head is leading any other department
                            const isStillHead = departments.some(
                                (dep) => dep.departmentHeadID === previousHeadID
                            );
                            if (!isStillHead) {
                                await axios.put(`https://api-govhub.tharuksha.com/api/staff/${previousHeadID}`, { role: "staff" });
                            }
                        }
                        await axios.put(`https://api-govhub.tharuksha.com/api/staff/${department.departmentHeadID}`, { role: "admin" });
                    }
                }

                toast.success("Department updated successfully");
            } else {
                // Create a new department
                await axios.post("https://api-govhub.tharuksha.com/api/departments", department);
                if (department.departmentHeadID) {
                    await axios.put(`https://api-govhub.tharuksha.com/api/staff/${department.departmentHeadID}`, { role: "admin" });
                }
                toast.success("Department added successfully");
            }

            navigate("/department");
            localStorage.removeItem("editId");
        } catch (error) {
            console.error("API error:", error);
            toast.error(error.response?.data?.message || "Failed to save department");
        }
    };


    return (
        <ThemeProvider theme={theme}>

            <div style={{ paddingTop: '20px' }}>
                <Card
                    sx={{
                        width: "97%",
                        margin: "auto",
                        marginTop: "20px",
                        marginBottom: "20px",
                        textAlign: 'left',
                    }}
                >
                    <CardHeader
                        subheader={isEdit ? "The information can be edited" : "Enter department details "}
                        title={isEdit ? "Edit Department" : "Add Department"}
                    />
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                width: "97%",
                                margin: "auto",
                                marginTop: "20px",
                                marginBottom: "20px",
                                boxShadow: "none",
                            }}
                        >
                            <Divider />
                            <CardContent sx={{ marginTop: "20px" }}>
                                <Grid container spacing={4}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Department Name"
                                            name="departmentName"
                                            value={department.departmentName}
                                            onChange={handleInputChange}
                                            required
                                            variant="outlined"
                                            error={!!errors.departmentName}
                                            helperText={errors.departmentName}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Business />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Department Description"
                                            name="departmentDescription"
                                            value={department.departmentDescription}
                                            onChange={handleInputChange}
                                            required
                                            variant="outlined"
                                            error={!!errors.departmentDescription}
                                            helperText={errors.departmentDescription}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Description />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Phone Number"
                                            name="phoneNumber"
                                            type="number"
                                            value={department.phoneNumber}
                                            onChange={handleInputChange}
                                            required
                                            variant="outlined"
                                            error={!!errors.phoneNumber}
                                            helperText={errors.phoneNumber}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Phone />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Email Address"
                                            name="emailAddress"
                                            type="email"
                                            value={department.emailAddress}
                                            onChange={handleInputChange}
                                            required
                                            variant="outlined"
                                            error={!!errors.emailAddress}
                                            helperText={errors.emailAddress}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Email />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Autocomplete
                                            options={staffMembers}
                                            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                            onChange={handleDepartmentHeadChange}
                                            value={staffMembers.find(member => member._id === department.departmentHeadID) || null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label="Department Head"
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Person />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Operating Hours"
                                            name="operatingHours"
                                            value={department.operatingHours}
                                            onChange={handleInputChange}
                                            type="time"
                                            required
                                            variant="outlined"
                                            error={!!errors.operatingHours}
                                            helperText={errors.operatingHours}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccessTime />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    p: 2,
                                    marginTop: "30px",
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="text"
                                    onClick={() => goBack()}
                                    startIcon={<ArrowBack />}
                                    sx={{ height: "30px", width: "100px", marginRight: "10px" }}
                                >
                                    Back
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<Cancel />}
                                    onClick={() => navigate("/department")}
                                    sx={{ height: "30px", width: "100px", marginRight: "10px" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    startIcon={isEdit ? <SaveAlt /> : <Save />}
                                    sx={{ height: "30px", width: "100px" }}
                                >
                                    {isEdit ? "Update" : "Save"}
                                </Button>
                            </Box>
                        </Card>
                    </form>
                </Card>
            </div>
        </ThemeProvider>
    );
};

export default AddEditDepartments;
